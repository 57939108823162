/* eslint-disable @typescript-eslint/no-unsafe-return */

import React, { Suspense, lazy } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import LogRocket from "logrocket";

import Preloader from "./components/preloader";
import BarCodeReader from "./components/barcode-reader";
import OrganizationSaleEntity from "./components/organization-sale-entity";
import SelectedUser from "./components/selected-user";

const DashboardOne = lazy(() => import("./pages/dashboard-one"));
const SignIn = lazy(() => import("./pages/signin"));
const ErrorNotFound = lazy(() => import("./pages/error-404"));
const Trainings = lazy(() => import("./pages/trainings"));
const TrainingsCalendar = lazy(() => import("./pages/trainings-calendar"));
const TrainingsSchedule = lazy(() => import("./pages/trainings-schedule"));
const TrainingView = lazy(() => import("./pages/training"));
const TrainingEdit = lazy(() => import("./pages/training-edit"));
const CreateTraining = lazy(() => import("./pages/training-create"));
const Users = lazy(() => import("./pages/users"));
const CompaniesList = lazy(() => import("./pages/companies"));
const Reports = lazy(() => import("./pages/reports"));
const ArticlesList = lazy(() => import("./pages/articles"));
const ArticleCategoriesList = lazy(() => import("./pages/article-categories"));
const POSScreen = lazy(() => import("./pages/pos"));
const TransactionsList = lazy(() => import("./pages/transactions"));

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("auth-token");
  // return the headers to the context so httpLink can read them

  if (!token) {
    return {};
  }

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const isProduction = process.env.REACT_APP_ENV === "production";

const httpLink = createHttpLink({
  uri: isProduction ? "https://api.upgym.io" : "http://localhost:5000",
});

const handlePaginationCache = (
  existing: any = null,
  incoming: any,
  other: any
) => {
  if (!existing || !other.args.after) {
    return incoming;
  }

  return {
    ...incoming,
    edges: [...existing.edges, ...incoming.edges],
  };
};

const client = new ApolloClient({
  cache: new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          getUsers: {
            keyArgs: false,
            merge: handlePaginationCache,
          },
          getTransactions: {
            keyArgs: false,
            merge: handlePaginationCache,
          },
        },
      },
    },
  }),
  link: authLink.concat(httpLink),
});

LogRocket.init("hofc6p/upgym");

const App: React.FC = () => {
  const token = window.localStorage.getItem("auth-token");

  return (
    <ApolloProvider client={client}>
      <OrganizationSaleEntity>
        <BarCodeReader>
          <Router>
            <SelectedUser>
              <Suspense fallback={<Preloader />}>
                {!token ? (
                  <Route exact path="*" component={SignIn} />
                ) : (
                  <Switch>
                    <Route exact path="/" component={DashboardOne} />
                    <Route exact path="/users" component={Users} />
                    <Route exact path="/trainings" component={Trainings} />
                    <Route
                      exact
                      path="/transactions"
                      component={TransactionsList}
                    />
                    <Route exact path="/reports" component={Reports} />
                    <Route exact path="/companies" component={CompaniesList} />
                    <Route exact path="/pos" component={POSScreen} />
                    <Route exact path="/articles" component={ArticlesList} />
                    <Route
                      exact
                      path="/article-categories"
                      component={ArticleCategoriesList}
                    />
                    <Route
                      exact
                      path="/trainings/calendar"
                      component={TrainingsCalendar}
                    />
                    <Route
                      exact
                      path="/trainings/schedule"
                      component={TrainingsSchedule}
                    />
                    <Route
                      exact
                      path="/trainings/create"
                      component={CreateTraining}
                    />
                    <Route
                      exact
                      path="/trainings/:id"
                      component={TrainingView}
                    />
                    <Route
                      exact
                      path="/trainings/:id/edit"
                      component={TrainingEdit}
                    />
                    <Route path="*" component={ErrorNotFound} />
                  </Switch>
                )}
                {/* <Route exact path="/dashboard-two" component={DashboardTwo} />
            <Route exact path="/dashboard-three" component={DashboardThree} />
            <Route exact path="/dashboard-four" component={DashboardFour} />
            <Route exact path="/signup" component={SignUp} />
            <Route exact path="/verify-account" component={VerifyAccount} />
            <Route exact path="/forgot-password" component={ForgotPassword} />
            <Route exact path="/error-500" component={Error500} />
            <Route exact path="/error-503" component={Error503} />
            <Route exact path="/error-505" component={Error505} />
            <Route exact path="/profile-view" component={ProfileView} />
            <Route exact path="/connections" component={Connections} />
            <Route exact path="/groups" component={Groups} />
            <Route exact path="/events" component={Events} />
            <Route exact path="/timeline" component={Timeline} />
            <Route exact path="/pricing" component={Pricing} />
            <Route exact path="/help-center" component={HelpCenter} />
            <Route exact path="/invoice" component={Invoice} />
            <Route exact path="/apps/calendar" component={Calendar} />
            <Route exact path="/apps/chat" component={Chat} />
            <Route exact path="/apps/contacts" component={Contacts} />
            <Route exact path="/apps/file-manager" component={FileManager} />
            <Route exact path="/apps/mail" component={Mail} /> */}
              </Suspense>
            </SelectedUser>
          </Router>

          {/* <SettingsCard /> */}
        </BarCodeReader>
      </OrganizationSaleEntity>
    </ApolloProvider>
  );
};

export default App;
