import React, { useState, createContext, useContext, useEffect } from "react";
import { useLazyQuery, useMutation } from "@apollo/client";
import { GET_USER_QUERY } from "src/graphql/queries/UserQueries";
import { useLocation } from "react-router-dom";
import { CHECK_IN_USER_MUTATION } from "src/graphql/mutations/UserMutations";
import { BarCodeReaderContext } from "../barcode-reader";

export const SelectedUserContext = createContext({
  user: null as any,
  setUser: (user: any) => {},
});

function SelectedUser({ children }: { children: JSX.Element }): JSX.Element {
  const location = useLocation();
  const [user, setUser] = useState<any>(null);
  const [getUser, { data: userData }] = useLazyQuery(GET_USER_QUERY, {
    fetchPolicy: "network-only",
  });
  const { value: barCodeValue, reset: resetBarCode } = useContext(
    BarCodeReaderContext
  );
  const [checkInUser] = useMutation(CHECK_IN_USER_MUTATION);

  useEffect(() => {
    // New scan
    const run = async () => {
      if (barCodeValue) {
        resetBarCode();
        console.log("Scan: ", barCodeValue);
        await getUser({
          variables: { accessKey: barCodeValue },
        });
      }
    };

    run();
  }, [barCodeValue]);

  useEffect(() => {
    console.log("find by ", { barCodeValue, userData });
    if (userData && userData.getUser) {
      setUser(userData.getUser);
      checkInUser({ variables: { userId: userData.getUser.id } });
    }
  }, [userData]);

  const value = {
    user,
    setUser,
  };

  return (
    <SelectedUserContext.Provider value={value}>
      {children}
    </SelectedUserContext.Provider>
  );
}

export default SelectedUser;
