import { useState, useEffect, createContext } from "react";

export const BarCodeReaderContext = createContext({
  value: "",
  reset: () => {},
});

function BarCodeReader({ children }: { children: JSX.Element }): JSX.Element {
  const [barcodeRead, setBarcodeRead] = useState("");

  const barcode = {
    timing: 1000,
    data: "",
  };

  const barcodeReaded = () => {
    if (barcode.data.length === 12 || barcode.data.length === 13) {
      const code =
        barcode.data.length === 12 ? `0${barcode.data}` : barcode.data;

      setBarcodeRead(code);
      barcode.data = "";
    } else if (barcode.data.length === 30) {
      setBarcodeRead(barcode.data);
      barcode.data = "";
    } else {
      barcode.data = "";
    }
  };

  let timeout = setTimeout(barcodeReaded, 500);

  useEffect(() => {
    window.addEventListener("keypress", (e) => {
      if (!(barcode.data.length === 0 || e.timeStamp - barcode.timing < 200)) {
        barcode.data = "";
      }

      barcode.data += e.key;
      barcode.timing = e.timeStamp;
      clearTimeout(timeout);
      timeout = setTimeout(barcodeReaded, 500);
    });
  }, []);

  const value = {
    value: barcodeRead,
    reset: () => setBarcodeRead(""),
  };

  return (
    <BarCodeReaderContext.Provider value={value}>
      {children}
    </BarCodeReaderContext.Provider>
  );
}

export default BarCodeReader;
