import {
  Button,
  Modal,
  ModalBody,
  ModalClose,
  ModalFooter,
  ModalHeader,
  ModalTitle,
} from "../../";
import { FC, useState } from "react";
import { ButtonProps } from "../button/button";

interface ButtonWithConfirmProps extends ButtonProps {
  confirmTitle: string;
  confirmMessage: string;
  confirmAction: string;
  onConfirm: () => void;
  onOpen?: () => void;
}

export const ButtonWithConfirm: FC<ButtonWithConfirmProps> = ({
  confirmTitle,
  confirmMessage,
  confirmAction,
  onConfirm,
  onOpen,
  ...props
}) => {
  const [modalVisible, setModalVisible] = useState(false);

  const handleConfirm = () => {
    onConfirm();
    setModalVisible(false);
  };

  return (
    <>
      <Modal show={modalVisible} onClose={() => setModalVisible(false)}>
        <ModalHeader>
          <ModalTitle>{confirmTitle}</ModalTitle>
          <ModalClose>x</ModalClose>
        </ModalHeader>
        <ModalBody>{confirmMessage}</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalVisible(false)}>
            Close
          </Button>
          <Button
            color={props.color === "secondary" ? "primary" : props.color}
            onClick={handleConfirm}
          >
            {confirmAction}
          </Button>
        </ModalFooter>
      </Modal>
      <Button
        {...props}
        onClick={() => {
          setModalVisible(true);
          if (onOpen) {
            onOpen();
          }
        }}
      />
    </>
  );
};
