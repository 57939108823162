import { useQuery } from "@apollo/client";
import React, { useState, useEffect, createContext } from "react";
import { CURRENT_USER_QUERY } from "src/graphql/queries/UserQueries";
import useLocalStorage from "use-local-storage";

export const OrganizationSaleEntityContext = createContext({
  currentEntityId: 0,
  currentEntity: null as any,
  setCurrentEntityId: (x: any) => {},
  saleEntities: [],
});

function OrganizationSaleEntity({
  children,
}: {
  children: JSX.Element;
}): JSX.Element {
  const { data: userData } = useQuery(CURRENT_USER_QUERY);

  const saleEntities = userData
    ? userData.currentUser.organization.saleEntities
    : [];

  const [selectedEntityId, setSelectedEntityId] = useLocalStorage(
    "selected_sale_entity_id",
    ""
  );

  useEffect(() => {
    if (!selectedEntityId && saleEntities.length > 0) {
      setSelectedEntityId(saleEntities[0].id);
    }
  }, [selectedEntityId, saleEntities]);

  const value = {
    currentEntityId: +selectedEntityId,
    currentEntity: saleEntities.find(({ id }: any) => id === +selectedEntityId),
    setCurrentEntityId: (x: any) => setSelectedEntityId(x),
    saleEntities,
  };

  return (
    <OrganizationSaleEntityContext.Provider value={value}>
      {children}
    </OrganizationSaleEntityContext.Provider>
  );
}

export default OrganizationSaleEntity;
