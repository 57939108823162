import { gql } from "@apollo/client";

export const USER_FRAGMENT = gql`
  fragment UserFragment on User {
    email
    role
    name
    surname
    dateOfBirth
    address
    phoneNumber
    avatar {
      thumbnail
    }
    memberships {
      id
      startDate
      endDate
      numberOfEntriesLeft
    }
    currentMembership {
      startDate
      endDate
      numberOfEntriesLeft
    }
    company {
      id
      name
    }
    accessKeys {
      id
      accessKey
    }
    notes {
      id
      note
      createdAt
    }
  }
`;

export const CURRENT_USER_QUERY = gql`
  query CurrentUser {
    currentUser {
      id
      ...UserFragment

      organization {
        name
        saleEntities {
          id
          name
          taxEnabled
        }
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const GET_USERS_QUERY = gql`
  query GetUsers(
    $first: Int
    $after: Int
    $roles: [String!]
    $searchTerm: String
  ) {
    getUsers(
      first: $first
      after: $after
      roles: $roles
      searchTerm: $searchTerm
    ) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          ...UserFragment
        }
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const GET_USER_QUERY = gql`
  query GetUser($id: Int, $accessKey: String) {
    getUser(id: $id, accessKey: $accessKey) {
      id
      ...UserFragment
    }
  }

  ${USER_FRAGMENT}
`;

export const GET_COMPANIES_QUERY = gql`
  query GetCompanies {
    getCompanies {
      id
      name
    }
  }
`;

export const GET_USER_ACTIVITIES_QUERY = gql`
  query GetUserActivities($userId: Int, $first: Int, $after: Int) {
    getUserActivities(userId: $userId, first: $first, after: $after) {
      pageInfo {
        hasNextPage
      }
      edges {
        cursor
        node {
          id
          type
          timestamp
          trainingEvent {
            id
            training {
              id
              name
            }
          }
        }
      }
    }
  }
`;

export const STATS_QUERY = gql`
  query GetStats {
    getStats {
      totalEntries {
        date
        value
      }

      lastUserEntries {
        timestamp
        user {
          id
          name
          surname
          avatar {
            thumbnail
          }
          currentMembership {
            startDate
          }
        }
      }
    }
  }
`;
