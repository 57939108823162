import gql from "graphql-tag";
import { USER_FRAGMENT } from "../queries/UserQueries";

export const UPDATE_USER_MUTATION = gql`
  mutation UpdateUser(
    $id: Int!
    $name: String
    $surname: String
    $dateOfBirth: String
    $address: String
    $phoneNumber: String
    $email: String
    $companyId: Int
    $role: String
  ) {
    updateUser(
      id: $id
      name: $name
      surname: $surname
      dateOfBirth: $dateOfBirth
      address: $address
      phoneNumber: $phoneNumber
      email: $email
      companyId: $companyId
      role: $role
    ) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const CREATE_USER_MUTATION = gql`
  mutation CreateUser(
    $name: String!
    $surname: String!
    $email: String!
    $dateOfBirth: String
    $address: String
    $phoneNumber: String
    $companyId: Int
  ) {
    createUser(
      name: $name
      surname: $surname
      email: $email
      dateOfBirth: $dateOfBirth
      address: $address
      phoneNumber: $phoneNumber
      companyId: $companyId
    ) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const SET_USER_PHOTO_MUTATION = gql`
  mutation SetUserPhoto($id: Int!, $photoBase64: String!) {
    setUserPhoto(id: $id, photoBase64: $photoBase64) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const APPLY_MEMBERSHIP_MUTATION = gql`
  mutation ApplyMembership(
    $articleId: Int!
    $userId: Int!
    $paymentType: String!
    $startDate: String
    $receiptId: String
  ) {
    applyMembership(
      articleId: $articleId
      userId: $userId
      paymentType: $paymentType
      startDate: $startDate
      receiptId: $receiptId
    ) {
      user {
        id
        ...UserFragment
      }
      transaction {
        id
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const CHECK_IN_USER_MUTATION = gql`
  mutation CheckInUser($userId: Int!, $manual: Boolean) {
    checkInUser(userId: $userId, manual: $manual) {
      success
      errors {
        key
        message
      }
    }
  }
`;

export const RESET_USER_PASSWORD_MUTATION = gql`
  mutation ResetUserPassword($userId: Int!) {
    resetUserPassword(userId: $userId) {
      success
      errors {
        key
        message
      }
    }
  }
`;

export const UPDATE_PASSWORD_MUTATION = gql`
  mutation UpdatePassword($userId: Int!, $newPassword: String!) {
    updatePassword(userId: $userId, newPassword: $newPassword) {
      success
      errors {
        key
        message
      }
    }
  }
`;

export const REGISTER_USER_ACCESS_KEY_MUTATION = gql`
  mutation RegisterUserAccessKey($userId: Int!, $accessKey: String!) {
    registerUserAccessKey(userId: $userId, accessKey: $accessKey) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const CREATE_USER_NOTE_MUTATION = gql`
  mutation CreateUserNote($userId: Int!, $note: String!) {
    createUserNote(userId: $userId, note: $note) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const UPDATE_USER_NOTE_MUTATION = gql`
  mutation UpdateUserNote($id: Int!, $note: String!) {
    updateUserNote(id: $id, note: $note) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;

export const DELETE_USER_NOTE_MUTATION = gql`
  mutation DeleteUserNote($id: Int!) {
    deleteUserNote(id: $id) {
      user {
        id
        ...UserFragment
      }
      errors {
        key
        message
      }
    }
  }

  ${USER_FRAGMENT}
`;
